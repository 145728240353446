import Parse from "parse"
import React, { useState, useEffect } from 'react'
import { useHistory  } from "react-router-dom"
import styled from "styled-components"
import { makeStyles } from '@material-ui/core/styles';
import { NavBar } from "../components/navbar"
import { getUserObjectId } from "../services/user"
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { getUserLocation, isLoggedIn } from "../services/user"
import Map from '../components/map';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));


async function addOrder(customer_info, order_data, history) 
{

  const request_params = { 
        store_user_object_id: order_data.store_user_object_id,
        store_object_id: order_data.store_object_id,
        customer_info: customer_info,
        delivery_information: {
            distance: 0,
            drop_time: 0,
            pickup_time: 0,
            possible_pickup_time: 0,
            delivery_duration: 0,
            status: 0,
        },
        platform: "web",
        order_value: Number(order_data.order_value),
        delivery_provider_object_id: order_data.delivery_provider_object_id,
        delivery_provider_user_object_id: order_data.delivery_provider_user_object_id,
        payment_method: order_data.payment_type,
    }

    request_params.delivery_information.distance = order_data.distance
    request_params.delivery_information.delivery_duration = Math.round(request_params.delivery_information.distance*10*30)
    request_params.delivery_information.pickup_time = order_data.pickup_time
    request_params.delivery_information.possible_pickup_time = order_data.pickup_time
    request_params.delivery_information.drop_time = order_data.pickup_time + request_params.delivery_information.delivery_duration

    if (order_data.delivery_provider_name)
    {
      request_params.delivery_information.delivery_provider_name = order_data.delivery_provider_name
    }
  
    // // setShowLoader(true)
    let result = await Parse.Cloud.run("addCustomOrder", request_params).catch(error => {
           console.log(error.message);
       });
    
    if (result && result.id)
    {
        history.goBack()            
    }
    else
    {
        // setShowLoader(false)
    }
    
};  




async function handleChangeStore(name, stores, setSelectedStoreName, setSelectedStoreObjectId, setSelectedStoreUserObjectId, setSelectedStoreLocation, callback)
{
    setSelectedStoreName(name);
    for (let i = 0; i < stores.length; i++) 
    {
      if (stores[i].name === name)
      {
        setSelectedStoreObjectId(stores[i].object_id)
        setSelectedStoreUserObjectId(stores[i].user_object_id)
        setSelectedStoreLocation(stores[i].location)
        if (callback)
        {
          callback(stores[i].delivery_providers)
        }
        break
      }
    }
};


async function getStores(setStores, setSelectedStoreObjectId, setSelectedStoreUserObjectId, setSelectedStoreName, setSelectedStoreLocation, callback) 
{
  const Store = Parse.Object.extend("Store");
  const query = new Parse.Query(Store);
  query.equalTo("isActive", true)
  query.equalTo("userObjectId", getUserObjectId())
  query.limit(500)
  const results = await query.find()
  if (results.length > 0)
  {
    let _stores = []
    for (let i = 0; i < results.length; i++) 
    {
      _stores.push({
        name: results[i].get("name"),
        object_id: results[i].id,
        user_object_id: results[i].get("userObjectId"),
        delivery_providers: results[i].get("deliveryProvider"),
        location: results[i].get("location")
      })
    }
    setStores(_stores)
    handleChangeStore(_stores[0].name, _stores, setSelectedStoreName, setSelectedStoreObjectId, setSelectedStoreUserObjectId, setSelectedStoreLocation, callback)
  }
}

async function getDeliveryProviders(delivery_providers_object_id, setSelectedDeliveryProviderObjectId, setSelectedDeliveryProviderUserObjectId, setSelectedDeliveryProviderName, setDeliveryProviders) 
{
  const request_params = { 
    delivery_provider_object_id: delivery_providers_object_id,
  }
  const results = await Parse.Cloud.run("getDeliveryProvider", request_params)
  let _items = []
  for (let i = 0; i < results.length; i++) 
  {
    _items.push({
      name: results[i].get("name"),
      object_id: results[i].id,
      user_object_id: results[i].get("userObjectId"),
    })
    
  }
  if (_items.length > 0)
  {
    setSelectedDeliveryProviderObjectId(_items[0].object_id)
    setSelectedDeliveryProviderUserObjectId(_items[0].user_object_id)
    setSelectedDeliveryProviderName(_items[0].name)
    setDeliveryProviders(_items)
  }
}

export default function Start({ match }) {

  const date = new Date();
  const [stores, setStores] = useState([])
  const [selectedStoreObjectId, setSelectedStoreObjectId] = useState("")
  const [selectedStoreLocation, setSelectedStoreLocation] = useState({})
  const [selectedStoreUserObjectId, setSelectedStoreUserObjectId] = useState("")
  const [selectedStoreName, setSelectedStoreName] = useState("")

  const [deliveryProviders, setDeliveryProviders] = useState([{object_id:"IFTfzqrWEy"}])
  const [selectedDeliveryProviderObjectId, setSelectedDeliveryProviderObjectId] = useState("IFTfzqrWEy")
  const [selectedDeliveryProviderUserObjectId, setSelectedDeliveryProviderUserObjectId] = useState("3RbopwPLtC")
  const [selectedDeliveryProviderName, setSelectedDeliveryProviderName] = useState("broadcast")

  const [deliveryAddressPhoneNumber, setDeliveryAddressPhoneNumber] = useState("")
  const [deliveryAddressPhoneNumberIsError, setDeliveryAddressPhoneNumberIsError] = useState(false)
  const [deliveryAddressCity, setDeliveryAddressCity] = useState("Poznań")
  const [deliveryAddressStreet, setDeliveryAddressStreet] = useState("")
  const [deliveryAddressStreetIsError, setDeliveryAddressStreetTimeIsError] = useState(false)
  const [deliveryAddressBuilding, setDeliveryAddressBuilding] = useState("")
  const [deliveryAddressBuildingIsError, setDeliveryAddressBuildingIsError] = useState(false)
  const [deliveryAddressFlat, setDeliveryAddressFlat] = useState("")
  const [deliveryAddressNote, setDeliveryAddressNote] = useState("")
  const [pickupTime, setPickupTime] = useState(0)
  const [pickupTimeIsError, setPickupTimeIsError] = useState(false)

  const [deliveryAddressString, setDeliveryAddressString] = useState("")

  const [paymentTypes, setPaymentTypes] = useState([{"name":"gotówka", "value":"cash"},{"name":"karta","value":"card"}])
  const [selectedPaymentType, setSelectedPaymentType] = useState("cash")

  const [orderValue, setOrderValue] = useState(0)

  const [distance, setDistance] = useState(0)


  const classes = useStyles();

  const history = useHistory();

  let customer_info = {
    phone_number: deliveryAddressPhoneNumber,
    notes: deliveryAddressNote,
    flat_number: deliveryAddressFlat,
    building_number: deliveryAddressBuilding,
    // latitude
    // longitude
    city: deliveryAddressCity,
    street: deliveryAddressStreet, 
  }

  let order_data = {
    store_user_object_id: selectedStoreUserObjectId,
    store_object_id: selectedStoreObjectId,
    pickup_time: pickupTime,
    delivery_provider_object_id: selectedDeliveryProviderObjectId,
    delivery_provider_user_object_id: selectedDeliveryProviderUserObjectId,
    delivery_provider_name: selectedDeliveryProviderName,
    payment_type: selectedPaymentType,
    order_value: orderValue,
    distance: distance
  }


  const handleChangeDeliveryProvider = (event) => {
    event.preventDefault();
    for (let i = 0; i < deliveryProviders.length; i++) 
    {
      if (deliveryProviders[i].name === event.target.value)
      {
        setSelectedDeliveryProviderObjectId(deliveryProviders[i].object_id)
        setSelectedDeliveryProviderUserObjectId(deliveryProviders[i].user_object_id)
        setSelectedDeliveryProviderName(deliveryProviders[i].name)
        break
      }
    }
  };

  function handleChangePickupTime(value)
  {
    const time_array = value.split(':');
    let date = new Date();
    date.setHours(time_array[0])
    date.setMinutes(time_array[1])
    const timeNow = Math.floor( date.getTime()*0.001 );
    setPickupTime(timeNow)
  }

  function handleChangePhoneNumber(event)
  {
    event.preventDefault();
    if (event.target.value.length === 9)
    { 
      event.target.value = "48" + event.target.value
    }
    setDeliveryAddressPhoneNumber(event.target.value)
  }

  function handleChangePaymentType(event)
  {
    event.preventDefault();
    setSelectedPaymentType(event.target.value)
  }

  function handleChangeOrderValue(event)
  {
    event.preventDefault();
    setOrderValue(event.target.value)
  }

  function updateInputHighlights()
  {
    if (pickupTime === 0)
    {
      setPickupTimeIsError(true)
    }
    else  
    {
      setPickupTimeIsError(false)
    }

    if (deliveryAddressPhoneNumber === "")
    {
      setDeliveryAddressPhoneNumberIsError(true)
    }
    else  
    {
      setDeliveryAddressPhoneNumberIsError(false)
    }

    if (deliveryAddressStreet === "")
    {
      setDeliveryAddressStreetTimeIsError(true)
    }
    else  
    {
      setDeliveryAddressStreetTimeIsError(false)
    }

    if (deliveryAddressBuilding === "")
    {
      setDeliveryAddressBuildingIsError(true)
    }
    else  
    {
      setDeliveryAddressBuildingIsError(false)
    }
  }

  async function checkAddress(event)
  {
    updateInputHighlights()
    if (event)
    {
      event.preventDefault();
    }
    const result = await getUserLocation( deliveryAddressCity, deliveryAddressStreet, deliveryAddressBuilding, selectedStoreLocation)
    if (result.id != null)
    {
      setDeliveryAddressString(result.string + " / " + deliveryAddressFlat)
      setDistance(result.distance)
      return result
    }
    else  
    {
      setDeliveryAddressString("nie znaleziono adresu")
      alert("nie znaleziono adresu")
      return false
    }
  }

  async function addCustomOrder(event)
  {
    updateInputHighlights()
    event.preventDefault();
    if (deliveryAddressPhoneNumber !== "" && deliveryAddressCity !== "" && deliveryAddressStreet !== "" && deliveryAddressBuilding !== "" && pickupTime > 0 && selectedStoreObjectId !== "")
    {
      const address_confirmed = await checkAddress()
      if (address_confirmed)
      {
        order_data.distance = address_confirmed.distance
      }
      addOrder(customer_info, order_data, history)
    }
    else  
    {
      console.log("complete the form")
    }

  }

  function callbackAfterStoreChange(delivery_providers_object_id)
  {
    getDeliveryProviders(delivery_providers_object_id, setSelectedDeliveryProviderObjectId, setSelectedDeliveryProviderUserObjectId, setSelectedDeliveryProviderName, setDeliveryProviders) 
  }

  function cancel()
  {
    history.goBack()
  }


  const mapIsReadyCallback = (map) => {
    console.log(map);
  };


  useEffect(() => {
    if (isLoggedIn())
    {
      getStores(setStores, setSelectedStoreObjectId, setSelectedStoreUserObjectId, setSelectedStoreName, setSelectedStoreLocation, callbackAfterStoreChange)
    }
    else
    {
        history.push("/")
    }
  }, [])

  
  return (
    <>
      <NavBar title={"nowe zlecenie"} cancelButtonCallback={cancel} />
      <Container >
        <form className={classes.form} noValidate autocomplete="off" >
              {stores.length>1?<FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">lokal</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedStoreName}
                  onChange={(event)=>{
                    event.preventDefault();
                    handleChangeStore(event.target.value, stores, setSelectedStoreName, setSelectedStoreObjectId, setSelectedStoreUserObjectId, setSelectedStoreLocation, callbackAfterStoreChange) 
                  } }
                  label="lokal"
                >
                  {stores.map(store => (
                      <MenuItem value={store.name} name={store.name}>{store.name}</MenuItem>
                    ))
                  }

                </Select>
              </FormControl>:null}

              {deliveryProviders.length>1?<FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Firma kurierska</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={selectedDeliveryProviderName}
                  onChange={handleChangeDeliveryProvider}
                  label="firma kurierska"
                >
                  {deliveryProviders.map(deliveryProvider => (
                      <MenuItem value={deliveryProvider.name} name={deliveryProvider.name}>{deliveryProvider.name}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>:null}

            <TextField
              error={deliveryAddressPhoneNumberIsError}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="phone_number"
              label="Numer telefonu"
              type="number"
              id="phone_number"
              autoFocus
              onChange={handleChangePhoneNumber} 
            />
            adres dostawy
            <div autocomplete="off" >
              <TextField
                style={{marginRight:"16px"}}
                variant="outlined"
                margin="normal"
                required
                id="miasto"
                label="Miasto"
                name="miasto"
                value={deliveryAddressCity}
                // autoComplete="email"
                onChange={(e) => setDeliveryAddressCity(e.target.value)} 
              />
              <TextField
                error={deliveryAddressStreetIsError}
                style={{marginRight:"16px"}}
                variant="outlined"
                margin="normal"
                required
                id="ulica"
                label="Ulica"
                name="ulica"
                // autoComplete="email"
                onChange={(e) => setDeliveryAddressStreet(e.target.value)} 
              />
              <TextField
                error={deliveryAddressBuildingIsError}
                style={{marginRight:"16px"}}
                variant="outlined"
                margin="normal"
                required
                id="numer_mieszkania"
                label="Numer budynku"
                name="numer_mieszkania"
                // autoComplete="email"
                onChange={(e) => setDeliveryAddressBuilding(e.target.value)} 
              />
              <TextField
                style={{marginRight:"16px"}}
                variant="outlined"
                margin="normal"
                id="flat_number"
                label="Numer mieszkania"
                name="flat_number"
                // autoComplete="email"
                onChange={(e) => setDeliveryAddressFlat(e.target.value)} 
              />
              <Button
                type="submit"
                
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick = {checkAddress}
              >sprawdź
              </Button>
            </div>
            <div style={{fontSize:"24px", color:"green"}}>
              {deliveryAddressString}
            </div>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="note"
              label="Notatka"
              // type="password"
              id="note"
              // autoComplete="current-password"
              onChange={(e) => setDeliveryAddressNote(e.target.value)} 
            />

            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Rodzaj płatności</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedPaymentType}
                onChange={handleChangePaymentType}
                label="firma kurierska"
              >
                {paymentTypes.map(payment_type => (
                    <MenuItem value={payment_type.value} name={payment_type.name}>{payment_type.name}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>

            <TextField
              id="order_value"
              label="do zapłaty (zł)"
              type="number"
              defaultValue="0"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChangeOrderValue} 
            />

            <TextField
              error={pickupTimeIsError}
              id="time"
              label="czas odbioru"
              type="time"
              defaultValue={date.getHours() + ":00"}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
              onChange={(e) => handleChangePickupTime(e.target.value) } 
            />

          {/*  <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick = {addCustomOrder}
            >
              dodaj
            </Button>
           {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>*/}
          </form>
      </Container>
    </>
  )
}
