import Parse from "parse"
import React, { useState, useEffect } from 'react'
import { useHistory  } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"
import { NavBar } from "../components/navbar"
import Container from '@material-ui/core/Container';
import { OrderRow } from "../components/orders"
import { isLoggedIn } from "../services/user"

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

async function fetchData(setOrdersStatusZero, setOrdersStatusOne, setOrdersStatusTwo) 
{
    const Order = Parse.Object.extend("Order");
    const orderQuery = new Parse.Query(Order);
    orderQuery.limit(50)
    orderQuery.descending("createdAt")
    orderQuery.lessThan("status", 3)
   
    const orderResults = await orderQuery.find();
    let _orders_status_0 = []
    let _orders_status_1 = []
    let _orders_status_2 = []
    for (let i = 0; i < orderResults.length; i++) 
    {
      
        const _store = orderResults[i].get("store")
        let _store_name = ""
        const _status = orderResults[i].get("status")
        let _completion_time = orderResults[i].get("completionTime")
        let _delivery_information = orderResults[i].get("deliveryInformation")
        let _delivery_provider_name = ""

        if (_delivery_information.delivery_provider_name)
        {
            _delivery_provider_name = _delivery_information.delivery_provider_name
        }

        if (_delivery_information && _delivery_information.drop_time)
        {
            _completion_time = _delivery_information.drop_time
        }
        if (!_delivery_information)
        {
            _delivery_information = {}
        }
        if (!_delivery_information.pickup_time)
        {
            _delivery_information.pickup_time = 0
        }
        if (_store)
        {
            _store_name = _store.get("name")
        }
        else  
        {
            _store_name = ""
        }

        let _completion_date_string = ""
        let _completion_time_string = ""
        let _completion_time_title_string = ""
        let _schedule_delivery_note = ""
        let _schedule_collect_note = ""
        const _schedule = orderResults[i].get("schedule")

        let _status_button_label = ""

        let _ststus_title = ""
        if (_status === 0)
        {
            _ststus_title = "nowe"
        }
        else if (_status === 1)
        {
            _ststus_title = "do odbioru"
            _status_button_label = "w dostawie"
        }
        else if (_status === 2)
        {
            _ststus_title = 'w dostawie'
            _status_button_label = "dostarczone"
        }
        else if (_status === 3)
        {
            _ststus_title = "zakończone"
        }

        if (_schedule) 
        {
            const _date_complete_time = new Date(_completion_time * 1000)
            _completion_date_string =  _date_complete_time.toLocaleDateString("pl-PL")
            if (_schedule.get("tag") === "today")
            {
                if (_status < 2)
                {
                    const _date = new Date(_delivery_information.pickup_time * 1000)
                    _completion_date_string =  _date.toLocaleDateString("pl-PL")
                    _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                    _completion_time_title_string = "odbiór o: "
                }
                else if (_status === 2)
                {
                    const _date = new Date(_completion_time * 1000)
                    _completion_date_string =  _date.toLocaleDateString("pl-PL")
                    _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                    _completion_time_title_string = "dostarcz do: "
                }
            }
            else if (_schedule.get("tag") === "next_day")
            {
                _completion_time_string = _completion_date_string
                _schedule_delivery_note = _schedule.get("deliveryNote")
                _schedule_collect_note = _schedule.get("collectNote")
            }
        } 
        else if (_delivery_information.pickup_time === 0)
        {
            if (_delivery_information.possible_pickup_time && _delivery_information.possible_pickup_time > 0) // time required to be accepted by the provider
            {
                const _date = new Date(_delivery_information.possible_pickup_time * 1000)
                _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                _completion_time_title_string = "możliwy odbiór o: "
            } 
            else
            {
                _completion_time_string = "czas odbiory nie został ustalony" 
            }
        }
        else if (_delivery_information.pickup_time > 0) // time i defined.
        {
            if (_status < 2)
            {
                const _date = new Date(_delivery_information.pickup_time * 1000)
                _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                _completion_time_title_string = "odbiór o: " 
            } 
            else if (_status === 2)
            {
                const _date = new Date(_completion_time * 1000)
                _completion_time_string = _date.getHours() + ":" + ("0" + _date.getMinutes()).substr(-2)
                _completion_time_title_string = "dostarcz do: " 
            } 
        } 
    
        const delivery_address = orderResults[i].get("customerInfo")
        let delivery_address_string = ""
        let delivery_note = ""
        if (delivery_address.city)
        {
            delivery_address_string = delivery_address.city + ", "
        }
        if (delivery_address.street)
        {
            delivery_address_string = delivery_address_string + delivery_address.street + " "
        }
        if (delivery_address.building_number)
        {
            delivery_address_string = delivery_address_string + delivery_address.building_number
        }
        if (delivery_address.flat_number)
        {
            delivery_address_string = delivery_address_string + " / " + delivery_address.flat_number
        }

        if (delivery_address.notes)
        {
            delivery_note = delivery_address.notes
        }

        let customer_phone_number = ""
        if (delivery_address.phone_number)
        {
            customer_phone_number = delivery_address.phone_number
        }

        const payment_info = orderResults[i].get("paymentInfo")
        let payment_type = ""
        if (payment_info.what_type)
        {
            if (payment_info.what_type === "card")
            {
                payment_type = "karta"
            }
            else if (payment_info.what_type === "cash")
            {
                payment_type = "gotówka"
            }
            else if (payment_info.what_type === "online")
            {
                payment_type = "online - zapłacone"
            }
      
        }
        
        const order_data = {
            object_id: orderResults[i].id,
            store_name: _store_name,
            order_id: orderResults[i].get("orderId"),
            products_quantity: orderResults[i].get("products").length,
            order_value: orderResults[i].get("orderValue"),
            delivery_type: orderResults[i].get("deliveryType"),
            status: _status,
            status_button_label: _status_button_label,
            completion_date: _completion_date_string,
            completion_time: _completion_time_string,
            completion_time_title: _completion_time_title_string,
            completion_time_stamp: _completion_time,
            schedule_delivery_note: _schedule_delivery_note,
            schedule_collect_note: _schedule_collect_note,
            delivery_address: delivery_address_string,
            delivery_note: delivery_note,
            customer_phone_number: customer_phone_number,
            payment_type: payment_type,
            ststus_title: _ststus_title,
            delivery_provider_name: _delivery_provider_name,
            customer_location: {
                longitude: orderResults[i].get("customerInfo").longitude,
                latitude: orderResults[i].get("customerInfo").latitude
            }
        }

        if (_status === 0)
        {
            _orders_status_0.push(order_data)
        }
        else if (_status === 1)
        {
            _orders_status_1.push(order_data)
        }
        else if (_status === 2)
        {
            _orders_status_2.push(order_data)
        }
    }
    setOrdersStatusZero(_orders_status_0)
    _orders_status_1.sort( function( a,b ) { if (a.completion_time_stamp && b.completion_time_stamp) { return Number(a.completion_time_stamp) - Number(b.completion_time_stamp) } } )
    setOrdersStatusOne(_orders_status_1)
    _orders_status_2.sort( function( a,b ) { if (a.completion_time_stamp && b.completion_time_stamp) { return Number(a.completion_time_stamp) - Number(b.completion_time_stamp) } } )
    setOrdersStatusTwo(_orders_status_2)
};


export default function Start({ match }) {
  const [canRefresh, setCanRefresh] = useState(true)

  const [ordersStatusZero, setOrdersStatusZero] = useState([])
  const [ordersStatusOne, setOrdersStatusOne] = useState([])
  const [ordersStatusTwo, setOrdersStatusTwo] = useState([])

  const classes = useStyles();
  const history = useHistory();

  function onAddOrderClicked()
  {
    history.push("/add_order")
  }

  function refresh()
  {
    setCanRefresh(false)
    fetchData(setOrdersStatusZero, setOrdersStatusOne, setOrdersStatusTwo) 
    setTimeout(()=>{ setCanRefresh(true) }, 5*1000)
  }

  function visibilitychange(e)
  {
       if (!document.hidden)
       {
            refresh()
       }
  }

  useEffect(() => {
    if (isLoggedIn())
    {
        fetchData(setOrdersStatusZero, setOrdersStatusOne, setOrdersStatusTwo) 
    }
    else
    {
        history.push("/")
    }
    // console.log(match.params.page_id)
    if (typeof window !== 'undefined' && window.addEventListener) 
    {
        window.removeEventListener('visibilitychange', visibilitychange)
        window.addEventListener('visibilitychange', visibilitychange, false)
    }
    // window.removeEventListener('visibilitychange', visibilitychange)
    // window.removeEventListener('visibilitychange', ()=>{})
    return () => {
         // Be sure to unsubscribe if a new handler is set
         window.removeEventListener('visibilitychange', visibilitychange)
       }
  }, [])
  
  return (
    <>
        <NavBar title={"ZiO delivery manager"} addOrder={onAddOrderClicked} myAccount={true}/>
        <Container >
     {/*     <Fab variant="extended" color="primary" aria-label="add" className={classes.margin} onClick={onAddOrderClicked}>
            <AddIcon className={classes.extendedIcon} />
            dodaj zlecenie
          </Fab>*/}
        {ordersStatusZero.length===0&&ordersStatusOne.length===0&&ordersStatusTwo.length===0?<p>brak aktywnych zamówień</p>:null}
        {ordersStatusZero.length>0?<>
            <h2 style={{color:"gray"}}>Nowe</h2>
            <OrderRow orders={ordersStatusZero} refresh={refresh} />
            </>:null}
        {ordersStatusOne.length>0?<>
            <h2 style={{color:"gray"}}>Do odbioru</h2>
            <OrderRow orders={ordersStatusOne} refresh={refresh} />
            </>:null}
        {ordersStatusTwo.length>0?<>
          <h2 style={{color:"gray"}}>W dostawie</h2>
          <OrderRow orders={ordersStatusTwo} refresh={refresh} />
          </>:null}
        </Container>

        {canRefresh?<Fab onClick={refresh} variant="extended" color="primary" style={{zIndex: "99", position: "fixed", bottom: "32px", right: "calc(50% - 128px)", width: "256px"}} >
            <RefreshIcon className={classes.extendedIcon} />
            odświerz liste
        </Fab>:null}
    </>
  )
}
