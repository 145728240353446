import React, { useState, useEffect } from 'react'
import styled from "styled-components"
import Fade from '@material-ui/core/Fade';
import MapIcon from '@material-ui/icons/Map';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PaymentIcon from '@material-ui/icons/Payment';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

import { orderInDelivery, orderCompleted } from "../services/order"

const Order = styled.div`
	height: auto;
	border-radius:6px;
	box-shadow: ${({isActive}) => (isActive === true ? `0px 0px 6px 0px grey;` : `0px 0px 2px 0px grey;` )}
	transition-duration: .3s;
	margin-bottom: 16px;
`

const Container = styled.div`
	display: grid;
	grid-template-columns: auto 128px;
`

const TextContainer = styled.div`
	padding: 16px;
`

const StoreName = styled.div`
	text-align: left;
	font-size: 24px;
`

const Label = styled.div`
	display: grid;
	grid-template-columns: 40px auto;
	text-align: left;
	color: gray;
	font-size: 14px;
	margin-top: 4px;
`

const LabelImportant = styled.div`
	display: grid;
	grid-template-columns: 40px auto;
	text-align: left;
	color: gray;
	font-size: 18px;
	margin-top: 8px;
`

const ButtonLabel = styled.div`
	text-align: center;
	color: gray;
	font-size: 16px;
`

const DeliveryTime = styled.div`
	text-align: center;
	margin-top: 6px;
	font-size: 24px;
	font-weight:800;
	padding-right: 6px;
`

const DeliveryTimeLabel = styled.div`
	text-align: center;
	margin-top: 40px;
	font-size: 18px;
`

const DeliveryTimeNote = styled.div`
	text-align: center;
	margin-top: 6px;
	font-size: 18px;
`

const ButtonsContainer = styled.div`
	display: grid;
	grid-template-columns: 150px auto 128px;
`

const TimeLabel = styled.div`
	text-align: center;
	font-size: 24px;
	width: 100%;
	margin: 8px;
`

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));
const times = [{name:"10 min", value: 600},{name:"20 min", value: 1200},{name:"30 min", value: 1800},{name:"40 min", value: 2400},{name:"50 min", value: 3000},{name:"1 godz.", value: 3600}];

export function OrderRow(props)
{
	const classes = useStyles();
	const [selectedOrder, setSelectedOrder] = useState({})
	const [openSelectTimeDialog, setOpenSelectTimeDialog] = useState(false)


	function openMap(delivery_address)
	{
		window.open("https://www.google.pl/maps/search/" + delivery_address)
	}

	function call(phone_number)
	{
		window.open("tel:+"+phone_number)
	}

	function changeStatus(order)
	{
		if (order.status === 1)
		{
			setSelectedOrder(order)
			setOpenSelectTimeDialog(true)
		}
		else if (order.status === 2)
		{
			orderCompleted(order.object_id, props.refresh)
		}
	}
	
	function handleCloseDialog()
	{
	    // onClose(selectedValue);
	    setOpenSelectTimeDialog(false)

	};

	function handleDialogListItemClick(value)
	{
	    setOpenSelectTimeDialog(false)
	    const date = new Date();
        let time_now = date.getTime();
		orderInDelivery(selectedOrder.object_id, Math.round(time_now*.001) + value, props.refresh)
		setSelectedOrder({})
	};

	return <>
		<Dialog onClose={handleCloseDialog} aria-labelledby="simple-dialog-title" open={openSelectTimeDialog}>
	      <DialogTitle style={{textAlign:"center"}} id="simple-dialog-title">Czas dostawy</DialogTitle>
	      <List>
	        {times.map((time) => (
	          <ListItem style={{textAlign:"center"}} button onClick={() => handleDialogListItemClick(time.value)} key={time.value}>
	            
	            <TimeLabel>
	            	{time.name} 
	            </TimeLabel>
	          </ListItem>
	        ))}

	
	      </List>
	    </Dialog>
		{props.orders.map(order => (
			<Fade in={true} timeout={1500}>
				<Order key={order.object_id} isActive={props.isActive}>
					<div>
						<Container>
							<TextContainer>
								<LabelImportant >
									<RoomIcon /> <b>{order.delivery_address}</b>
								</LabelImportant>
								<LabelImportant>
									<ReceiptIcon/><b>{order.delivery_note}</b>
								</LabelImportant>
								<br/>
								<Label >
									<b style={{fontSize:"20px", marginLeft:"6px"}}>#</b>{order.order_id}
								</Label>
								<Label >
									<LocalPhoneIcon/> {order.customer_phone_number}
								</Label>
								<Label >
									<PaymentIcon/> {order.payment_type}
								</Label>
								{order.delivery_provider_name?<Label >
									<DriveEtaIcon/>Kurier: {order.delivery_provider_name}
								</Label>:null}
							
							</TextContainer>
							<> 
								{order.completion_time?<div>
									<DeliveryTimeLabel >
										{order.completion_time_title}
									</DeliveryTimeLabel>
									<DeliveryTime>
										{order.completion_time}
									</DeliveryTime>
									{order.schedule_delivery_note&&order.schedule_delivery_note!=null?<DeliveryTimeNote >
										{order.schedule_delivery_note}
									</DeliveryTimeNote>:null}
								</div>:null}
							</>
						</Container>
						
						<ButtonsContainer>
						   <Grid container className={classes.root} spacing={2}>
						      <Grid item xs={12}>
						        <Grid container justifyContent="center" spacing={4}>
						            <Grid key={"phone"} item >
						              <Button variant="contained" color="primary" onClick={()=>{changeStatus(order) } }>{order.status_button_label}</Button>
						              <ButtonLabel style={{marginTop:"8px"}}>
						              	zmień status
						              </ButtonLabel>
						            </Grid>
						      </Grid>
						    </Grid>
						   </Grid>
						   <div/>
							<Grid container className={classes.root} spacing={2}>
						      <Grid item xs={12}>
						        <Grid container justifyContent="center" spacing={4}>
						     
						            <Grid key={"phone"} item onClick={()=>{call(order.customer_phone_number) } } >
						              <PhoneIcon style={{fontSize:"40px"}} /><br/>
						              	<ButtonLabel>
						              		tel.
						              	</ButtonLabel>
						            </Grid>

						            <Grid key={"map"} item onClick={()=>{openMap(order.delivery_address) } }>
						              <MapIcon style={{fontSize:"40px"}} /><br/>
						              	<ButtonLabel>
						              		mapa
						              	</ButtonLabel>
						            </Grid>

						      </Grid>
						    </Grid>
						   </Grid>
						</ButtonsContainer>
					</div>

				</Order>
			</Fade>
			))
		}
	</>
}