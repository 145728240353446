import Parse from "parse";
import axios from "axios";
import { getDistance } from "../services/helper"

function updateLocalStorage(user_data)
{
    let _address = user_data.get("address")
    if (_address)
    {
      // console.log(_address)
      // localStorage.setItem("primary_address_city", _address.city?_address.city:"")
      // localStorage.setItem("primary_address_street", _address.street?_address.street:"")
      // localStorage.setItem("primary_address_building_number", _address.building_number?_address.building_number:"")
      // localStorage.setItem("primary_address_flat_number", _address.flat_number?_address.flat_number:"")
      // localStorage.setItem("primary_address_phone_number", _address.phone_number?_address.phone_number:"")
      // localStorage.setItem("primary_address_postcode", _address.postcode?_address.postcode:"")
      // localStorage.setItem("primary_address_notes", _address.notes?_address.notes:"")
      // localStorage.setItem("primary_address_longitude", _address.longitude?_address.longitude:0)
      // localStorage.setItem("primary_address_latitude", _address.latitude?_address.latitude:0)
    }
}

export const loginUser = async( username, password, callback ) => {
  if (username !== null && username !== "" && password !== null && password !== "")
  {
      try {
          const user_data = await Parse.User.logIn(username, password);
          // console.log(user_data)
          updateLocalStorage(user_data)
          
          // localStorage.setItem("user_token", user_data.getSessionToken())
          const what_type = user_data.get("whatType")
          if (what_type === "business")
          {
            if (callback) 
            { 
              callback() 
              console.log("callback")
            }
            console.log("ok")
            return "ok"
          }
          else  
          {
            return "error"
          }
      } catch (error) {
          console.log(error)
          return "error"
      }
  }
  else
  {
    let user_data = await Parse.User.current();
    // console.log(user_data)
    if (user_data) // get user  via api.
    {
      updateLocalStorage(user_data)
      if (callback) 
      { 
        callback() 
        console.log("callback") 
      }
      console.log("ok")
      return "ok"
    }
    else
    {
      console.log("no_credentials")
      return "no_credentials"
    }
  }
};

export const Logout = async() => {
    await Parse.User.logOut()
    clearUserData()
};



export function isLoggedIn()
{
    let user_data = Parse.User.current()
    if (user_data == null)
    {
      return false
    }
    else 
    {
      updateLocalStorage(user_data)
      return true
    }
};

export function getUserObjectId()
{
    const user_data = Parse.User.current()
    if (user_data == null)
    {
      return null
    }
    else 
    {
      return user_data.id
    }
};

export function getUserLogin()
{
    const user_data = Parse.User.current()
    if (user_data == null)
    {
      return null
    }
    else 
    {
      return user_data.attributes.username
    }
};

export const refreshUserData = async() => {
  if (Parse.User.current())
  {
    Parse.User.current().fetch()
  }
};


export function clearUserData()
{
    // localStorage.removeItem("primary_address_city", "")
    // localStorage.removeItem("primary_address_street", "")
    // localStorage.removeItem("primary_address_building_number", "")
    // localStorage.removeItem("primary_address_flat_number", "")
    // localStorage.removeItem("primary_address_phone_number", "")
    // localStorage.removeItem("primary_address_postcode", "")
    // localStorage.removeItem("primary_address_notes", "")
    // localStorage.removeItem("primary_address_longitude")
    // localStorage.removeItem("primary_address_latitude")
    // localStorage.removeItem("zio_loyalty_card_object_id")
    // localStorage.removeItem("current_store_data")
    // localStorage.removeItem("current_store_object_id")
    // localStorage.removeItem("zio_loyalty_card_points")
};

export function getUserData()
{
  return Parse.User.current()
}


export const getUserLocation = async( city, street, building, myLocation ) => {
  console.log(`https://api.geoapify.com/v1/geocode/search?city=${city}&street=${street}&housenumber=${building}&apiKey=e1e3c364b319441fa84b684fbe99d7e0&limit=50&lang=pl&filter=countrycode:pl&bias=proximity:${myLocation.latitude},${myLocation.longitude}`)
    const response = await axios.get(`https://api.geoapify.com/v1/geocode/search?city=${city}&street=${street}&housenumber=${building}&apiKey=e1e3c364b319441fa84b684fbe99d7e0&limit=50&lang=pl&filter=countrycode:pl&bias=proximity:${myLocation.latitude},${myLocation.longitude}`);
    var newArray = {}
    // console.log(response)
    for (var i = 0; i < response.data.features.length; i++) 
    {
      // if (response.data.features[i].properties.city.toLowerCase() === city.toLowerCase())
      // {
        if (response.data.features[i].properties.city && response.data.features[i].properties.lat && response.data.features[i].properties.lon && response.data.features[i].properties.street && response.data.features[i].properties.housenumber)
        {
          newArray.id = i
          newArray.city = response.data.features[i].properties.city
          newArray.street = response.data.features[i].properties.street ? response.data.features[i].properties.street : response.data.features[i].properties.suburb
          newArray.building_number = response.data.features[i].properties.housenumber
          newArray.latitude = response.data.features[i].properties.lat
          newArray.longitude = response.data.features[i].properties.lon
          newArray.postcode = response.data.features[i].properties.postcode
          newArray.distance = getDistance({ latitude: response.data.features[i].properties.lat, longitude: response.data.features[i].properties.lon },{ latitude: myLocation.latitude, longitude: myLocation.longitude  }  )
          newArray.string = "(" + newArray.distance + "km) " + newArray.city + ", " + newArray.street + " " + newArray.building_number
          break
        }
      }
    // }
   
    return newArray
};