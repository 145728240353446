import Parse from "parse";


export const orderInDelivery = async( order_object_id, drop_time, callback ) => 
{

  let request_params = {
    orderObjectId: order_object_id,
    dropTime: drop_time,
    skip_push: true
  }
  let result = await Parse.Cloud.run("orderIsInDelivery", request_params)
  if (result)
  {
    if (callback)
    {
      callback()
    }
  }
  // console.log(result)
};

export const orderCompleted = async( order_object_id, callback ) => {
  let request_params = {
    orderObjectId: order_object_id,
    skip_push: true
  }
  let result = await Parse.Cloud.run("orderIsCollected", request_params)
  if (result)
  {
    if (callback)
    {
      callback()
    }
  }
  // console.log(result)
};

