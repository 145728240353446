import React from 'react';
import { useHistory  } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function NavBar({title, cancelButtonCallback, addOrder, myAccount, backButton}) {
  const classes = useStyles();
  const history = useHistory();

  function onMyAccountClicked()
  {
    history.push("/my_account")
  }

  function onBackClicked()
  {
    history.goBack()
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {backButton?<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={onBackClicked}>
            <ArrowBackIcon />
          </IconButton>:null}
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {cancelButtonCallback?<Button color="inherit" onClick={cancelButtonCallback}>anuluj</Button>:null}
          {addOrder?<Button color="inherit" onClick={addOrder} >Dodaj zlecenie</Button>:null}
          {myAccount?<Button color="inherit" onClick={onMyAccountClicked} >moje konto</Button>:null}
        </Toolbar>
      </AppBar>
    </div>
  );
}