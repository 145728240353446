import Parse from "parse"
import React, { useState, useEffect } from 'react'
import { useHistory  } from "react-router-dom"
import { makeStyles } from '@material-ui/core/styles';
import { NavBar } from "../components/navbar"
import { Logout, isLoggedIn } from "../services/user"
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function Start({ match }) {

  const [order, setOrder] = useState({})
  const classes = useStyles();
  const history = useHistory();

  function goBack()
  {
    history.goBack()
  }

  async function logOut()
  {
    await Logout()
    history.push("/")
  }

  useEffect(() => {
    if (!isLoggedIn())
    {
        history.push("/")
    }
  }, [])
  
  return (
    <div className={classes.root}>
        <NavBar title={"Moje konto"} backButton={true} />
        <Container>
            <p>Jesteś zalogowany. Aby zalogować sie na inne konto, wyloguj sie</p>
            <Button color="inherit" onClick={logOut} >wyloguj</Button>
        </Container>
    </div>
  )
}
